<template>
          <!-- Table to display -->
          <table class="table">
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="column in columns"
                  :key="column.dataKey"
                >
                  <div
                    text
                    block
                    depressed
                    @click="sortBy(column.dataKey)"
                  >
                    <span class="caption text-capitalize">
                      {{ column.name }} <mdicon name="arrow-up-down" :size="16"/>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in formatrow" :key="row.code">
                <td
                  v-for="column in columns"
                  :key="column.dataKey"
                  :class="column.align"
                >
                  {{ row[column.dataKey] }}
                </td>
              </tr>
            </tbody>
          </table>
  </template>
  
  <script>
  export default {
    components: {},
  
    props: {
      columns: Array,
      rows: Array,
    },
  
    data() {
      return {
        formatrow: this.rows,
        sort: "Asc",
      };
    },
  
    created() {
      // format the value using the column's 'formatValue' method
      for (let i = 0; i < this.formatrow.length; i++) {
        for (let j = 0; j < this.columns.length; j++) {
          try {
            this.formatrow[i][this.columns[j]["dataKey"]] = this.columns[j].formatValue(this.formatrow[i][this.columns[j]["dataKey"]]);
          } catch (e) {
            console.log("'formatValue' method is not present in data key:", this.columns[j]["dataKey"]);
            continue;
          }
        }
      }
    },
    methods: {
      sortBy(prop) {
        if (this.sort === "Asc") {
          this.formatrow.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
          this.sort = "Desc";
        } else if (this.sort === "Desc") {
          this.formatrow.sort((a, b) => (a[prop] > b[prop] ? -1 : 1));
          this.sort = "Asc";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
    width: 100%;
    font-size: 12px;
    font-family: 'Verdana';
    line-height: 0.9;
  }
  
  th {
    border-bottom: 2px solid #ddd;
    text-align: left;
  }
  
  td.left {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  td.right {
    text-align: right;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  td.transactionValue {
    width: 3%;
  }
  td.guid {
    width: 300px;
  }
  td.date {
    width: 200px;
  }
  td.name {
    width: 8%;
  }
  td.id {
    width: 4%;
  }
  td.center {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .text-capitalize {
    text-transform: capitalize;
  }
  
  </style>