import { ref } from "vue";
import store from "../../store";
import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from "@microsoft/signalr";

export const signalRSymbol = Symbol("signalr");

const createSignalRConnection = () => ({
  connectionStarted: ref(false),
  connection: new HubConnectionBuilder()
    .withUrl(process.env.VUE_APP_API_URL + '/gamificationhub', {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => store.user?.access_token
    })
    .configureLogging(LogLevel.Information)
    .build()
});

export const startConnection = (signalrConnection) => {
  signalrConnection.connection.start()
    .then(() => {
      signalrConnection.connectionStarted.value = true;
    })
    .catch(() => {
      signalrConnection.connectionStarted.value = false;
      setTimeout(startConnection(signalrConnection), 5000);
    })
};


export default {
    install: (app) => {
        const signalrConnection = createSignalRConnection();
        
        app.provide(signalRSymbol, signalrConnection);

        const { connectionStarted, connection } = signalrConnection;

        // connection.invoke
        connection.onclose(() => {
          connectionStarted.value = false;
          startConnection(signalrConnection);
        });
    }
  }
  