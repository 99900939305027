<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="winlose-actions">
    <h2>Select Win/Lose game</h2>
    
    <ul class="list-buttons">
      <li v-for="game in games" v-bind:key="game.id">
        <button class="button" @click.stop="chooseGame(game)" :class="(chosenGame!=null && game.id==chosenGame.id?'disabled':'enabled')">{{game.name}} >></button>
      </li>
    </ul>
    <span v-if="chosenGame" class="title">Select your wagers</span>
    <ul v-if="chosenGame" class="vertical-inputs">
      <li v-for="wl in chosenGame.winloses" v-bind:key="wl.id">
        <p><label for="wager">Wager {{wl.name}} wager ({{wl.minPrice}}-{{wl.maxPrice}}): </label>
          <input type="number" v-model="wl.wager" :min="chosenGame.minPrice" :max="chosenGame.maxPrice"> SEK
        </p>
      </li>
    </ul>
    <button v-if="chosenGame" class="button" @click.stop="buy()">Buy ticket</button>
    
    <div class="unfinished" v-if="unfinishedTickets.length > 0">
      <h2>Unfinished tickets</h2>
      <ul class="unfinished-list">
        <li v-for="ticket in unfinishedTickets" v-bind:key="ticket.correlationId">
          <strong>Correlation ID: {{ ticket.correlationId }} </strong> - <a href="#" @click.stop="finalizeGameCollection(ticket)">Finalize GameCollection</a>
          <br/><span v-if="ticket.tickets[0].autoCompleteOn" class="autocomplete">( AutoCompletes on: {{ $filters.formatDate(ticket.tickets[0].autoCompleteOn) }} )</span>
          <br/> - Wager: {{ ticket.gamePrice }} SEK <br/>
          <ul>
            <li v-for="partialTicket in ticket.tickets" v-bind:key="partialTicket.id">
              Ticket for: <strong><i>{{ partialTicket.gameName }}</i></strong> ID: {{ partialTicket.id }} <br/> - Wager: {{ partialTicket.price }} SEK  - <a href="#" @click.stop="getTicket(partialTicket)">View Result</a> - <a v-if="!partialTicket.finalized" href="#" @click.stop="finalizeSingleTicket(partialTicket)">Finalize Ticket</a><span v-else>Finalized</span>
              <span v-if="partialTicket.prizeValue"><br/><strong>You're playing for: {{ partialTicket.prizeValue }} x {{ partialTicket.price }} SEK </strong></span>
              <span v-if="partialTicket.multiplier"><br/><strong>Multiplier: {{ partialTicket.multiplier }} </strong></span>
              <span v-if="partialTicket.winAmount"><br/><strong>Result: Winner! 😀 You won: {{ partialTicket.winAmount }} SEK </strong></span>
              <span v-if="partialTicket.noWin"><br/><strong>Result: No win! 😒</strong></span><br/>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="ticket-result" v-if="lastTicketResultWinAmount !== null">
      <h2>Ticket result</h2>
      <strong>Result:</strong> {{ lastTicketResultWinAmount }} SEK
    </div>
  </div>
</template>

<script>
import store from '../store.js';
import { updateBalance, buyGameCollection, getUnfinishedTickets, finalizeGameWithCorrelationId, getWinLoseTicket, finalizeGameTicket } from '../api.js';

const { VUE_APP_GAMES_WINLOSE } = process.env;


export default {
  name: 'WinLoseGame',
  props: {
  },
  mounted() {
    this.games = JSON.parse(VUE_APP_GAMES_WINLOSE);
  },
  data() {
    return {
      isWaitingForServer: false,
      chosenGame: null,
      store: store,
      currentCorrelationId: '',
      currentStatus: '',
      unfinishedTickets: [],
      lastTicketResultWinAmount: null,
      games: [],
      wagers: []
    }
  },
  methods: {
    chooseGame(_game) {
      this.chosenGame = _game;
      this.checkUnfinishedTickets();
      this.lastTicketResult = null;
    },
    checkUnfinishedTickets() {
      this.isWaitingForServer = true;
      getUnfinishedTickets(this.chosenGame.id).then((data) => {
        this.unfinishedTickets = data;
      }).catch(() => {
        this.unfinishedTickets = null;
      });
      this.isWaitingForServer = false;
    },
    buy() {
      this.lastTicketResultWinAmount = null;
      if (!this.chosenGame) return false;
      
      let buyArguments = this.chosenGame.winloses.map(function (element) { 
        return {
          gameType: "WinLose",
          pluginGameId: element.id,
          wager: element.wager,
        }
      });

      this.isWaitingForServer = true;
      let response = buyGameCollection(this.chosenGame.id, buyArguments).then((data) => {
        console.log('buy', this.chosenGame, data);
        console.dir(response);
        this.checkUnfinishedTickets();
        updateBalance();
        this.isWaitingForServer = false;
      }).catch(() => {
        this.isWaitingForServer = false;
      });
    },
    async getTicket(_ticket){
      let result = await getWinLoseTicket(_ticket.id);
      _ticket.prizeValue = result.prizeValue;
      await new Promise(r => setTimeout(r, 1500));
      _ticket.multiplier = result.multiplier;
      await new Promise(r => setTimeout(r, 2000));
      _ticket.winAmount = result.winAmount;
      if(result.winAmount == 0){
        _ticket.noWin = true;
      }
    },
    finalizeGameCollection(_ticket) {
      this.isWaitingForServer = true;

      finalizeGameWithCorrelationId(_ticket.correlationId).then((data)  => {
        let totalWin = 0;

        data.tickets.forEach(ticket => {

          if (ticket.isWinner) { 
            getWinLoseTicket(ticket.id).then((data) => {
                return totalWin += data.winAmount;
              }).then((value) => {
                this.lastTicketResultWinAmount = value;
              });
            } else {
              this.lastTicketResultWinAmount = totalWin;
            }
        });
        
        this.isWaitingForServer = false;
        this.checkUnfinishedTickets();
        updateBalance();
      });
    },
    async finalizeSingleTicket(_ticket) {
      this.isWaitingForServer = true;

      var result = await finalizeGameTicket("PUT", "WinLose", _ticket.id);
      this.lastTicketResultWinAmount = result.prize;
      this.checkUnfinishedTickets();
      updateBalance();
      this.isWaitingForServer = false;
    }
  }
}
</script>

<style scoped>

.unfinished-list > li {
  margin: 14px;
}

ul.list-buttons {
  list-style-type: none;
  padding: 0;
}
ul.vertical-inputs {
  list-style-type: none;
  padding: 0;
}
ul.list-buttons li {
  display: inline-block;
  margin: 0 50px 0 0;
}
ul.vertical-inputs li {
  display: block;
  margin: 0 0 0 0;
  width: 50%;
}

ul.vertical-inputs li p input {
  width: 10%;
}

.title {
  font-weight: bold;
}

li p {
  margin: 0
}
.autocomplete {
  font-size: 11px;
}

</style>
