<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="gamification-actions">
    <div id="gameSessionStuff">
      <h2>Gamification</h2>
      <div>
        <h2>Game Session</h2>
        <div>
          <label for="gamificationId">GamificationId: </label>
          <input type="number" v-model.number="gamificationId"/>
        </div>
        <div>
          <label for="correlationId">CorrelationId: </label>
          <input type="text" v-model="correlationId" />
        </div>

        <button class="button" @click.stop="startGameSession()">Start Game Session</button>
        <button class="button" @click.stop="skipGameSession()">Skip Game Session</button>
        <button class="button" @click.stop="finishGameSession()">Finish Game Session</button>
      </div>

      <div>
        <h3>Variables</h3>
        <div>
          <label for="eventValue">Event Value: </label>
          <input type="text" v-model="eventValue"/>
        </div>
        <div>
          <label for="eventType">Event Type:</label>
          <input type="text" v-model="eventType"/>
        </div>        
        <div>
          <label for="eventnumericalScoreValue">Numerical Score Value: </label>
          <input type="number" v-model.number="eventNumericalScoreValue"/>
        </div>
        <div>
          <label for="eventStringScoreValue">String Score Value: </label>
          <input type="text" v-model="eventStringScoreValue"/>
        </div>
        <div>
          <label for="eventDate">Event Date: </label>
          <input type="text" v-model="eventDate"/>
        </div>
        
        <button class="button" @click.stop="addGameEvent()">Add Game Event</button>
      </div>

      <div>
        <h3>Variables</h3>
        <div>
          <label for="variableIdentifier">Variable Identifier: </label>
          <input type="text" v-model="variableIdentifier"/>
        </div>
        <div>
          <label for="variableValue">Variable Value: </label>
          <input type="number" v-model.number="variableValue"/>
        </div>
        <button class="button" @click.stop="customerVariableUpdate()">Customer Variable Update</button>
      </div>
      <div>
        <h3>Currency</h3>
        <div>
          <label for="currencyId">Currency Id: </label>
          <input type="number" v-model.number="currencyId"/>
        </div>
        <div>
          <label for="currencyAmount">Variable Value: </label>
          <input type="number" v-model.number="currencyAmount"/>
        </div>
        <button class="button" @click.stop="addCustomerCurrency()">Add Customer Currency</button>
      </div>
    </div>
    <div id="messages">
        <h2>Messages</h2>
        <textarea style="min-width:800px;min-height:600px;" v-model="messages"></textarea>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { signalRSymbol, startConnection } from "../plugins/SignalRPlugin/GamificationHub";
import store from '../store.js';

export default {
  name: 'GamificationHub',
  props: {
  },
   created() {
    const signalrConnection = inject(signalRSymbol);

    const { connection } = signalrConnection;
    
    this.gamificationHub = connection;

    connection.on('GameSessionStarted', ({ sessionId }) => this.gameSessionStarted(sessionId));
    connection.on('GameSessionFinished', () => this.gameSessionFinished());
    connection.on('GameSessionSkipped', () => this.gameSessionSkipped());
    connection.on('AddedGameEvent', () => this.addedGameEvent());
    connection.on('CustomerCurrencyAdded', () => this.customerCurrencyAdded());
    startConnection(signalrConnection);
  },
  mounted() {
  },
  data() {
    return {
      gamificationHub: null,
      isWaitingForServer: false,
      store: store,
      messages: "",
      // basics
      gamificationId: null,
      correlationId: null,
      // events
      eventValue: null,
      eventType: null,
      eventNumericalScoreValue: null,
      eventStringScoreValue: null,
      eventDate: null,
      // variables
      variableIdentifier: null,
      variableValue: null,
      // currencies
      currencyId: null,
      currencyAmount: null
    }
  },
  methods: {
    gameSessionStarted(...sessionId) {
      this.messages += 'GameSessionStarted ' + sessionId + '\n';
    },
    gameSessionFinished() {
      this.messages += 'Game Session Finished \n';
    },
    gameSessionSkipped() {
      this.messages += 'Game Session Skipped \n';
    },
    addedGameEvent() {
      this.messages += 'Added Game Event \n';
    },
    customerCurrencyAdded() {
      this.messages += 'Customer Currency Added \n';
    },
    startGameSession() {
      let params = {
        gamificationId: this.gamificationId,
        correlationId: this.correlationId
      };
      this.gamificationHub.invoke("StartGameSession", params);
    },
    skipGameSession() {
      this.gamificationHub.invoke("SkipGameSession");
    },
    finishGameSession() {
      this.gamificationHub.invoke("FinishGameSession");
    },
    addGameEvent() {
      let params = {
        eventValue: this.eventValue,
        eventType: this.eventType,
        numericalScoreValue: this.eventNumericalScoreValue,
        stringScoreValue: this.eventStringScoreValue,
        eventDate: this.eventDate
      };
      this.gamificationHub.invoke("AddGameEvent", params);
    },
    customerVariableUpdate() {
      let params = {
        variableIdentifier: this.variableIdentifier,
        value: this.variableValue,
      };
      this.gamificationHub.invoke("CustomerVariableUpdate", params);
    },
    addCustomerCurrency() {
      let params = {
        currencyId: this.currencyId,
        amount: this.currencyAmount
      };
      this.gamificationHub.invoke("AddCustomerCurrency", params);
    }
  }
}
</script>

<style scoped>

.gamification-actions {
  display:flex;
}

#gameSessionStuff {
  width: 50%;
}

#messages {
  width: 50%;
}

div {
  margin-bottom: 10px;
}

label {
  display: inline-block;
  width: 15em;
  text-align: left;
}

button {
  display: inline-block;
  margin-right: 1em;
}

</style>
