<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="transactions">
    
    <h2>Your Latest Transactions</h2>
    <span>
      <mdicon name="skip-previous" @click="previousData"/>
        ({{pageNumber * pageSize + 1}} - {{pageNumber * pageSize + (store.user.transactions?.length ?? pageSize) }})
      <mdicon name="skip-next" @click="nextData"/>
      <span v-if="store.user.transactions?.length < pageSize"> (end reached)</span>
    </span>
    <span style="margin-left: 20px;">Items per page: </span>
    <select @change="getData" v-model.number="pageSize" >
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="1000">1000</option>
    </select>
    <br/>
    <br/>
    <DataTable v-if="store.user.transactions" :columns="columns" :rows="store.user.transactions"/>
  </div>
</template>

<script>
import store from '../store.js';
//import axios from 'axios';
//import * as qs from 'qs'
import { transactions } from '../api.js';
import DataTable from './DataTable.vue';

export default {
  name: 'TransactionPage',
  components: {
    DataTable
  },
  props: {
  },
  data() {
    return {
      isWaitingForServer: true,
      store,
      columns: [
        {
          dataKey: "createdDate",
          name: "created Date",
          align: "date",
          formatValue(value) {
            let m = new Date(value);
            return m.getUTCFullYear() + "-" +
                  ("0" + (m.getUTCMonth()+1)).slice(-2) + "-" +
                  ("0" + m.getUTCDate()).slice(-2) + " " +
                  ("0" + m.getUTCHours()).slice(-2) + ":" +
                  ("0" + m.getUTCMinutes()).slice(-2) + ":" +
                  ("0" + m.getUTCSeconds()).slice(-2) + " UTC";
          }
        },
        {
          dataKey: "sourceId",
          name: "Source Id",
          align: "guid",
        },
        {
          dataKey: "correlationId",
          name: "correlation Id",
          align: "guid"
        },
        {
          dataKey: "gameName",
          name: "Game Name",
          align: "name"
        },
        {
          dataKey: "gameType",
          name: "Game Type",
          align: "name"
        },
        {
          dataKey: "pluginGameId",
          name: "plugin game id",
          align: "id"
        },
        {
          dataKey: "moneyValue",
          name: "money Value",
          align: "transactionValue",
          formatValue(value) {
            return value.toLocaleString();
          }
        },
        {
          dataKey: "bonusValue",
          name: "bonus Value",
          align: "transactionValue",
          formatValue(value) {
            return value.toLocaleString();
          }
        },
        {
          dataKey: "goodsValue",
          name: "goods Value",
          align: "transactionValue",
          formatValue(value) {
            return value.toLocaleString();
          }
        },
        {
          dataKey: "message",
          name: "message",
          align: "left"
        },
        {
          dataKey: "transactionType",
          name: "transaction Type",
          align: "left"
        },
        {
          dataKey: "roundId",
          name: "round Id",
          align: "left",
          formatValue(value) {
            return value.toLocaleString();
          }
        },
      ],
      pageNumber: 0,
      pageSize: 20
    }
  },
  async created() {
    this.isWaitingForServer = true;
    await transactions(this.pageNumber, this.pageSize);
    this.isWaitingForServer = false;
  },
  methods: {
    async getData(){
      this.pageNumber = 0;
      // hack? does not update table unless setting null here  
      store.user.transactions = null;
      this.isWaitingForServer = true;
      await transactions(this.pageNumber, this.pageSize);
      this.isWaitingForServer = false;
    },
    async previousData(){
      if(this.pageNumber === 0)
        return;
      // hack? does not update table unless setting null here  
      store.user.transactions = null;
      this.isWaitingForServer = true;
      await transactions(--this.pageNumber, this.pageSize);
      this.isWaitingForServer = false;
    },
    async nextData(){
      if (store.user.transactions.length < this.pageSize)
        return;
      // hack? does not update table unless setting null here
      store.user.transactions = null;
      this.isWaitingForServer = true;
      await transactions(++this.pageNumber, this.pageSize);
      this.isWaitingForServer = false;
    }
  }
}
</script>

<style scoped>
</style>
