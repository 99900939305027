<template>
  <h2>Logged in</h2>
</template>

<script>

export default {
  name: 'LoggedIn',
}
</script>
