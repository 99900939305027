<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="raffle-actions">
    <h2>Select Raffle game</h2>
    <ul class="list-buttons">
      <li v-for="game in games" v-bind:key="game.id">
        <button class="button" @click.stop="chooseGame(game)" :class="(chosenGame!=null && game.id==chosenGame.id?'disabled':'enabled')">{{ game.name }} - {{ game.cost }} SEK</button>
      </li>
    </ul>
    <div class="raffle-info" v-if="currentRaffleInfo">
      Current Raffle round: {{ currentRaffleInfo.activeRoundId }}<br>
      Next drawing: {{ currentRaffleInfo.nextDrawingTime }}
    </div>
    <div class="my-tickets" v-if="myTicketsInActiveRound?.length > 0">
      <h2>My tickets in this round</h2>
      <ul class="my-tickets-list">
        <li v-for="ticket in myTicketsInActiveRound" v-bind:key="ticket.correlationId">
          Number: {{ ticket }}
        </li>
      </ul>
    </div>
    <div class="ticket-buy" v-if="chosenGame != null">
      <p v-if="chosenGame.canSelectNumber"><label for="raffleNumber">Select number:</label><input type="number" v-model="raffleNumber" name="raffleNumber" :min="chosenGame.minNumber" :max="chosenGame.maxNumber"></p>
      <button class="button" @click.stop="buy()">Buy ticket</button>
    </div>
    <div class="rounds" v-if="currentRaffleRounds != null">
      <h2>Previous rounds in this Raffle</h2>
      <ul class="list-buttons">
        <li v-for="round in currentRaffleRounds" v-bind:key="round.roundId">
          <button class="button" :disabled="currentPreviousRoundId == round.roundId" @click.stop="getPreviousRoundTickets(round.roundId)">{{ new Date(round.drawDate).toLocaleString() }} - {{ round.roundWinnings }} SEK</button>
        </li>
      </ul>
      <div v-if="currentPreviousRoundTickets != null">
        <h3>My tickets in this round</h3>
        <ul>
          <li v-for="ticket in currentPreviousRoundTickets" v-bind:key="ticket.ticketNumber">Number: {{ ticket.ticketNumber }}, Result: {{ ticket.winAmount }} SEK</li>
        </ul>
      </div>
    </div>
    <div class="ticket-result" v-if="lastTicketResult">
      <h2>Ticket result</h2>
      <strong>Result:</strong> {{ lastTicketResult.prize }} SEK
    </div>
  </div>
</template>

<script>
import store from '../store.js';
import { updateBalance, buyGameCollection, getRaffleInfo, getMyRaffleTicketsInRound, getRaffleRounds, getMyRaffleRoundResults } from '../api.js';

const { VUE_APP_GAMES_RAFFLE } = process.env;


export default {
  name: 'RaffleGame',
  props: {
  },
  mounted() {
    this.games = JSON.parse(VUE_APP_GAMES_RAFFLE);
  },
  data() {
    return {
      isWaitingForServer: false,
      chosenGame: null,
      currentRaffleInfo: null,
      store: store,
      currentCorrelationId: '',
      currentStatus: '',
      currentRaffleRounds: null,
      currentPreviousRoundId: null,
      currentPreviousRoundTickets: null,
      myTicketsInActiveRound: null,
      games: []
    }
  },
  methods: {
    chooseGame(_game) {
      this.chosenGame = _game;
      this.myTicketsInActiveRound = null;
      this.currentRaffleRounds = null;
      this.currentPreviousRoundTickets = null;
      this.currentPreviousRoundId = null;

      this.updateRaffleInfo();
    },
    updateRaffleInfo() {
      getRaffleInfo(this.chosenGame.raffles[0]).then((data) => {
        this.currentRaffleInfo = data;
        this.getMyTicketsInCurrentRound();

        this.getPreviousRounds();
      }).catch(() => {

      })
    },
    getMyTicketsInCurrentRound() {
      getMyRaffleTicketsInRound(this.chosenGame.raffles[0]).then((data) => {
        if (data.length <= 0) {
          this.myTicketsInActiveRound = null;
        } else {
          this.myTicketsInActiveRound = data;
        }
      }).catch(() => {

      });
    },
    getPreviousRounds() {
      getRaffleRounds(this.chosenGame.raffles[0]).then((data) => {
        this.currentRaffleRounds = data;
      });
    },
    getPreviousRoundTickets(_roundId) {
      this.currentPreviousRoundId = _roundId;
      getMyRaffleRoundResults(this.chosenGame.raffles[0], _roundId).then((data) => {
        this.currentPreviousRoundTickets = data;
      });
    },
    buy() {
      if (!this.chosenGame) return false;

      this.isWaitingForServer = true;
      let args = {};
      if (this.chosenGame.canSelectNumber) {
        args.number = this.raffleNumber;
      }
      let buyArguments = [{
        gameType: "Raffle",
        pluginGameId: this.chosenGame.raffles[0],
        ...args
      }];
      buyGameCollection(this.chosenGame.id, buyArguments).then(() => {
        this.getMyTicketsInCurrentRound();
        updateBalance();
        this.isWaitingForServer = false;
      }).catch(() => {
        this.isWaitingForServer = false;
      });
    },
  }
}
</script>

<style scoped>

ul.list-buttons {
  list-style-type: none;
  padding: 0;
}
ul.list-buttons li {
  display: inline-block;
  margin: 0 10px 10px 0;
}
</style>
