<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="user-buttons">
    <h2>Log in as</h2>
    <ul>
      <li v-for="user in users" v-bind:key="user.username">
        <button class="button" @click.stop="login(user)" :disabled="isLoggingIn">{{ user.username }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import store from '../store.js';
//import axios from 'axios';
//import * as qs from 'qs'
import { login } from '../api.js';

const { VUE_APP_USERS } = process.env;


export default {
  name: 'UserLogin',
  props: {
  },
  mounted() {
    this.users = JSON.parse(VUE_APP_USERS);
  },
  data() {
    return {
      isWaitingForServer: false,
      store: store,
      users: []
    }
  },
  methods: {
    login(_user) {
      this.isWaitingForServer = true;

      login(_user.username, _user.password).then(() => {
        this.isWaitingForServer = false;
      }).catch(() => {
        this.isWaitingForServer = false;
      });

    }
  }
}
</script>

<style scoped>
.user-buttons {
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px 0 0;
}
</style>
